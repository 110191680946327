<template>
  <el-main>
    <el-form class="el-form-search" label-width="120px">
      <el-form-item label="投票名称：">
        <el-input size="small" v-model="vote_name"></el-input>
      </el-form-item>
      <el-form-item label="被投票人名称：">
        <el-input size="small" v-model="name"></el-input>
      </el-form-item>
      <el-form-item label=" " label-width="25px">
        <el-button size="small" @click="search" type="primary">搜索</el-button>
        <el-button size="small" @click="searchClear" type="text">清除搜索条件</el-button>
      </el-form-item>
    </el-form>
    <el-table :data="list" :header-cell-style="{ 'background-color': '#F8F9FA' }" row-key="id">
      <template slot="empty">
        <No />
      </template>
      <el-table-column prop="vote_name" label="投票名称" align="center"></el-table-column>
      <el-table-column prop="name" label="被投票人名称" align="center"></el-table-column>
      <el-table-column prop="vote_user_count" label="投票人数" align="center"></el-table-column>
      <el-table-column prop="by_vote_sum" sortable label="被投票票数" align="center"></el-table-column>
      <el-table-column prop="vote_ranking"  label="当前排名" align="center"></el-table-column>
      <el-table-column prop="money" label="操作" align="center">
        <template v-slot="{ row }">
          <el-button @click="seeVoteList(row)" size="small" type="text">投票人列表</el-button>
        </template>
      </el-table-column>
    </el-table>
    <Paging :total="total_number" :page="page" :pageNum="rows" @updatePageNum="updateData"></Paging>
    <el-dialog title="队伍查看" :visible.sync="showUserVote" width="600px">
      <div class="vote-name">被投票人名称：{{ by_vote_name }}</div>
      <el-table :data="userVoteList" :header-cell-style="{ 'background-color': '#F8F9FA' }" row-key="id">
        <template slot="empty">
          <No />
        </template>
        <el-table-column prop="nickname" label="投票人" align="center">
          <template v-slot="{ row }">
            <userInfo :name="row.nickname" :avatar="row.avatar"></userInfo>
          </template>
        </el-table-column>
        <el-table-column prop="update_time" label="投票时间" align="center">
          <template v-slot="{ row }">
            {{ getDateformat(row.update_time) }}
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
          @current-change="handleCurrentChange"
          :page-size="userVoteRows"
          :current-page="userVotePage"
          layout="prev, pager, next"
          :page-sizes="[6]"
          :total="user_vote_total_number"
        ></el-pagination>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showUserVote = !1">取 消</el-button>
        <el-button type="primary" @click="showUserVote = !1">确 定</el-button>
      </span>
    </el-dialog>
  </el-main>
</template>

<script>
import Paging from '@/components/paging';
import userInfo from '@/components/userInfo';
import { getDateformat } from '@/util/getDate';
export default {
  components: {
    Paging,
    userInfo,
  },
  data() {
    return {
      page: 1,
      rows: 10,
      vote_name: '',
      name: '',
      list: [],
      total_number: 0,
      showUserVote: !1,
      userVotePage: 1,
      userVoteRows: 6,
      user_vote_total_number: 0,
      vote_person_id: '',
      userVoteList: [],
      by_vote_name: '',
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getDateformat(val) {
      return getDateformat(val);
    },
    updateData(val, status) {
      if (status == 0) {
        this.rows = val;
      } else {
        this.page = val;
      }
      this.getList();
    },
    search() {
      this.page = 1;
      this.rows = 10;
      this.getList();
    },
    searchClear() {
      this.page = 1;
      this.rows = 10;
      this.name = '';
      this.vote_name = '';
      this.getList();
    },
    handleCurrentChange(val) {
      this.userVotePage = val;
      this.getUserVoteList();
    },
    getList() {
      this.$axios
        .post(this.$api.sports.voteResultList, {
          page: this.page,
          rows: this.rows,
          vote_name: this.vote_name,
          name: this.name,
        })
        .then(res => {
          if (res.code == 0) {
            this.total_number = res.result.total_number;
            this.list = res.result.list;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    seeVoteList(row) {
      this.userVotePage = 1;
      this.userVoteRows = 6;
      this.vote_person_id = row.vote_person_id;
      this.showUserVote = !0;
      this.by_vote_name = row.name;
      this.getUserVoteList();
    },
    getUserVoteList() {
      this.$axios
        .post(this.$api.sports.voteUserList, {
          page: this.userVotePage,
          rows: this.userVoteRows,
          vote_person_id: this.vote_person_id,
        })
        .then(res => {
          if (res.code == 0) {
            this.userVoteList = res.result.list;
            this.user_vote_total_number = res.result.total_number;
            console.log(this.user_vote_total_number);
          } else {
            this.$message.error(res.msg);
          }
        });
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  background: #fff;
  .vote-name {
    font-size: 15px;
    font-weight: bold;
    margin-bottom: 15px;
  }
  .pagination {
    margin-top: 15px;
    display: flex;
    justify-content: flex-end;
  }
}
</style>